
import { ref } from "vue";
export default {
  setup() {
    const message = ref("");
    return {
      message,
    };
  },
};
