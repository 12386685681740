<template>
  <div class="wrapper">
    <van-field
      class="input"
      v-model="message"
      rows="2"
      autosize
      type="textarea"
      maxlength="200"
      placeholder="请输入反馈建议"
      show-word-limit
    />
    <van-button round block type="primary" native-type="submit">
      <i class="rmy-icon rmy-icon-tongguo"></i>
      提交
    </van-button>
  </div>
</template>

<script  lang="ts">
import { ref } from "vue";
export default {
  setup() {
    const message = ref("");
    return {
      message,
    };
  },
};
</script>

<style  lang="scss" scoped>
.wrapper {
  padding: 16px;
  .input {
    margin-bottom: 16px;
    :deep .van-field__control {
      height: 300px !important;
    }
  }
  :deep .van-cell::after {
    border: none !important;
  }
  :deep .rmy-icon-tongguo {
    font-size: 12px;
    margin-right: 4px;
  }
  :deep .van-button__text {
    font-size: 16px;
  }
}
</style>